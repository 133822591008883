.editor-container {
    border: 1px solid lightgray;
    border-radius: 4px;
    .buttons-container {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: .5rem;
        .divider {
            color: #ccc;
        }
    }
}

.data-map-select {
    font-size: 16px;
    width: 100%;
}

.data-map-container {
    display: inline;
    background: #ddd;
    border: 1px solid gray;
    border-radius: 4px;
    color: gray;
    font-size: 10pt;
    margin-inline: 4px;
    padding-block: 4px;
    padding-inline: 6px;
}

.table-attributes-modal {

    padding-block: 2rem;

    Row {
        align-items: center;
    }

    label {
        text-align: center;
    }
}
html,
body,
#root {
  height: 100%;

}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  overflow-y: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: none;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

ant-btn:where(.css-dev-only-do-not-override-f64ans).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
  border-color: none !important;
  background: #ffffff;
}

.ant-btn:focus {
  /* color: inherit !important;  */
  color: white !important;
  border: 1px solid rgb(217, 217, 217); 
}

.ant-btn-primary {
  color: white !important;
}
.users-page-form {

    .ant-form-item {
        margin-bottom: 1rem !important;
    }

    h2 {
        text-align: center;
        margin-top: 1rem
    }

    h3 {
        color: #015B93;
        margin: 0;
        margin-top: 1rem;
    }

    label {
        font-size: .8em;
        font-weight: bold;
        margin-inline: .25rem;
        /* color: green; */
    }
}